// src/components/navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../img/royal-printers-logo.png";

function Navbar() {
    const handleClose = () => {
        const inputField = document.getElementById('MsAccessInputField');
        if (inputField) {
            inputField.value = 'CloseWebView';
        }
    };

    return (
        <div>
            <div className="topPurp">
                <b>A Sustainable Vancouver Printing Company</b>
            </div>

            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <table>
                        <tr>
                            <td><Link to="/"><img src={logo} className="App-logo" style={{ paddingTop: '0em', width: 160, height: 'auto' }} alt="logo" /></Link></td>
                            <td></td>
                            <td><Link className="rp-nav-link" to="/quotes">Quote List</Link></td>
                            <td><Link className="rp-nav-link" to="/report">ReportView</Link></td>
                        </tr>
                    </table>

                    <Button variant="purple" onClick={handleClose}>Close</Button>

                </div>
            </nav>
        </div>
    );
}

export default Navbar;

