import React from "react";
import "./css/App.css";
// src/index.js or src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home.js";
import Login from "./pages/login.js";
import ReportView from "./pages/reportview.js";
import NotFound from "./pages/notfound.js";
import Navbar from "./components/navbar.js";
import QuoteList from './pages/quotelist.js';

function App() {
  return (
    <Router>
      {/* <div className="container" style={{backgroundColor: '#ff0000'}}> */}
      <div className="container-fluid">
        <Navbar />
        {/* 
        <div>
          Footer!
        </div> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/report" element={<ReportView />} />
          <Route path="/quotes" element={<QuoteList />} />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
