// src/pages/notfound.js

import React from 'react';

function NotFound() {
    return (
        <div className="container">
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>

            <input type='hidden' id='MsAccessInputField' value='NotFoundPage'/>
        </div>
    );
}

export default NotFound;
