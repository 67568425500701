// src/pages/Login.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

const Login = () => {
    const [quoteId, setQuoteId] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extract the quote_id from the query parameter
        const params = new URLSearchParams(location.search);
        const quoteIdParam = params.get('quote_id');

        if (quoteIdParam) {
            const id = parseInt(quoteIdParam, 10);
            setQuoteId(id);

            // Check if the quote_id is even or odd
            if (id % 2 === 0) {
                // If even, navigate to the ReportView page
                navigate(`/report?quote_id=${id}`);
            } else {
                // If odd, show the login form
                setShowLogin(true);
            }
        }
        else {
            // If odd, show the login form
            setShowLogin(true);
        }

    }, [location, navigate]);

    const handleLogin = (e) => {
        e.preventDefault();
        // Simulate a login and then navigate to ReportView
        navigate(`/report?quote_id=${quoteId}`);
    };

    if (!showLogin) {
        return null; // Don't show anything while redirecting
    }

    return (
        <div className="container">
            <h1>Login</h1>
            <Form onSubmit={handleLogin}>
                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Login
                </Button>
            </Form>

            <input type='hidden' id='MsAccessInputField' value='LoginPage'/>
        </div>
    );
};

export default Login;
