import React from "react";

function Home() {
  return (
    <div className="home_mesg">
      <h3>Welcome to Royal Printers Web Utils!</h3>
      <p>QS POS V3 Demo</p>

      <input type='hidden' id='MsAccessInputField' value='HomePage'/>
    </div>
  );
}

export default Home;
