// src/pages/ReportView.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const ReportView = () => {
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    // Extract the quote_id from the query parameter
    const params = new URLSearchParams(location.search);
    const quoteId = params.get('quote_id');

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await axios.get(`/api/report_view.php?quote_id=${quoteId}`);
                setReportData(response.data);
            } catch (err) {
                setError('Error fetching report data');
            } finally {
                setLoading(false);
            }
        };

        if (quoteId) {
            fetchReportData();
        }
    }, [quoteId]);



    // DYNAMIC RETURNS
    if (loading) return <div>Loading... <input type='hidden' id='MsAccessInputField' value='ReportViewLoading' /></div>;
    if (error) return <div>{error} <input type='hidden' id='MsAccessInputField' value='ReportViewError' /></div>;

    return (
        <div className="container">
            <h1>Report for Quote ID: {quoteId}</h1>
            {reportData ? (
                <div>
                    <p><strong>Customer:</strong> {reportData.author}</p>
                    <p><strong>Category:</strong> {reportData.category}</p>
                    <p><strong>Quote:</strong> {reportData.text}</p>
                    {/* Render additional report data as needed */}
                </div>
            ) : (
                <p>No data available for this quote.</p>
            )}

            <input type='hidden' id='MsAccessInputField' value='ReportViewDetails' />
        </div>
    );
};

export default ReportView;
