// src/pages/QuoteList.js

import React, { useState } from "react";
import { Table, InputGroup, FormControl, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const QuoteList = () => {
  // Placeholder data
  const initialQuotes = [
    {
      id: 11217,
      author: "Mr. Paper",
      text: "Card Stock, Doublesided, Glossy Side A, Matte Side B, Die-Cut, 20,000pcs",
      category: "Product Backing",
    },
    {
      id: 123,
      author: "Newton Inc.",
      text: "Velvet Card Stock, off-white, slight embossing, 600pcs",
      category: "Business Cards",
    },
    {
      id: 17,
      author: "Sir Post-A-Lots",
      text: "Heavy Stock, Oil Ink, Die-Cut 1000pcs",
      category: "Flyers",
    },
    {
      id: 3224,
      author: "Brochures R-US",
      text: "Light Stock, Waterbased Ink, 100,000pcs",
      category: "Pamphlets",
    },
    {
      id: 413130,
      author: "Mrs. Paper",
      text: "Glossy Cover Stock, Newsprint Inner Stock, Waterbased Ink, 300pcs",
      category: "Manuals",
    },
    // Add more quotes as needed
  ];

  const [quotes, setQuotes] = useState(initialQuotes);
  const [sortField, setSortField] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Sorting function
  const sortQuotes = (field) => {
    const sortedQuotes = [...quotes].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setQuotes(sortedQuotes);
    setSortField(field);
  };

  // Filtering function
  const filteredQuotes = quotes.filter(
    (quote) =>
      quote.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const viewReport = (quoteId) => {
    navigate(`/report?quote_id=${quoteId}`);
  };
  
  return (
    <div className="container">
      <h1>Quote List</h1>

      {/* Search Bar */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search quotes..."
          aria-label="Search quotes"
          aria-describedby="basic-addon2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {/* Quote Table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => sortQuotes("author")}>Customer</th>
            <th onClick={() => sortQuotes("category")}>Category</th>
            <th onClick={() => sortQuotes("text")}>Quote</th>
          </tr>
        </thead>
        <tbody>
          {filteredQuotes.map((quote) => (
            <tr key={quote.id}>
              <td>{quote.author}</td>
              <td>{quote.category}</td>
              <td>{quote.text}</td>
              <td>
                <Button variant="royal" onClick={() => viewReport(quote.id)}>
                  View Quote
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <input type='hidden' id='MsAccessInputField' value='QuoteListPage'/>
    </div>
  );
};

export default QuoteList;
